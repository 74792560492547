import { css, unsafeCSS } from 'lit'

export const themeDataToCSS = (data: Theme.Tokens) => {
  const isProduction = import.meta.env.PROD && !import.meta.env.VITE_IS_PREVIEW

  if (isProduction) {
    return css`
      :host {
        ${unsafeCSS(
          Object.entries(data)
            .map(([key, value]) => `${key}: ${value};`)
            .join('\n'),
        )}
      }
    `
  }

  return css`
    :host {
      ${unsafeCSS(
        Object.entries(data)
          .map(([key, value]) => `${key}: var(--chat-${key.replace('--', '')}, ${value});`)
          .join('\n'),
      )}
    }
  `
}

// export const getTokens = (theme: string) => {
//   return import(`./tokens/${theme}.json`)
// }

// export const getIconset = (theme: string) => {
//   return import(`./icons/${theme}.json`)
// }
