import { LitElement, css, html } from 'lit'
import { customElement, property } from 'lit/decorators.js'

@customElement('dwc-icon-button')
export class IconButton extends LitElement {
  @property({ type: String })
  icon: IconName = 'close'

  @property({ type: String })
  size: IconSize = 'medium'

  render() {
    return html`<button>
      <dwc-icon name=${this.icon} size=${this.size}></dwc-icon>
    </button>`
  }

  static styles = css`
    :host {
      display: inline-block;
    }

    button {
      font-family: var(--font-family);
      border: none;
      background: none;
      cursor: pointer;
      color: var(--color-shade-300);
      transition: color 0.2s;
      padding: 0;
    }

    button:hover {
      color: var(--color-shade-400);
    }
  `
}

declare global {
  interface HTMLElementTagNameMap {
    'dwc-icon-button': IconButton
  }
}
