export const WC_PREFIX = 'dwc-chat'

export const getComponentName = (childName?: string) =>
  childName ? `${WC_PREFIX}-${childName}` : WC_PREFIX

export const sleep = (value: number) => new Promise((resolve) => setTimeout(resolve, value))

export async function generateHMAC(jsonString: string): Promise<string> {
  return new Promise((resolve, reject) => {
    const data = new TextEncoder().encode(jsonString)
    const key = new TextEncoder().encode(import.meta.env.VITE_API_SECRET)

    crypto.subtle
      .importKey('raw', key, { name: 'HMAC', hash: 'SHA-256' }, true, ['sign'])
      .then((key) => {
        return crypto.subtle.sign('HMAC', key, data)
      })
      .then((signature) => {
        const hashArray = Array.from(new Uint8Array(signature))
        const hashHex = hashArray.map((byte) => byte.toString(16).padStart(2, '0')).join('')
        resolve(hashHex)
      })
      .catch((error) => reject(error))
  })
}
