import { LitElement, PropertyValues, css, html } from 'lit'
import { customElement, property, state } from 'lit/decorators.js'
import { RefOrCallback, ref } from 'lit/directives/ref.js'

@customElement('dwc-messages')
export class Messages extends LitElement {
  @property({ type: Array })
  messages = new Array<ChatMessage>()

  @state()
  private _isScrolledBottom = true

  private _el: HTMLInputElement | null = null

  private _isAutoScroll = true

  private _updateScroll() {
    if (!this._el) return
    const _isAutoScroll = this._isAutoScroll
    this._el.scrollTop = this._el.scrollHeight
    this._isAutoScroll = _isAutoScroll
  }

  private _handleScroll() {
    if (!this._el) return

    const isScrolledBottom =
      this._el.scrollTop + this._el.clientHeight >= this._el.scrollHeight - 24

    if (isScrolledBottom !== this._isScrolledBottom) {
      this._isScrolledBottom = isScrolledBottom
      this.requestUpdate()
    }
  }

  private _handleScrollToBottom() {
    this._updateScroll()
    this._isAutoScroll = true
  }

  connectRef(element: HTMLInputElement) {
    this._el = element
    this._el?.addEventListener('scroll', this._handleScroll.bind(this))
  }

  disconnectedCallback(): void {
    super.disconnectedCallback()
    this._el?.removeEventListener('scroll', this._handleScroll.bind(this))
  }

  willUpdate(changedProperties: PropertyValues<this>) {
    if (changedProperties.has('messages') && this._isAutoScroll) {
      setTimeout(() => {
        this._updateScroll()
      }, 0)
    }
  }

  private _renderMessage(message: ChatMessage, index: number) {
    const nextMessage = this.messages[index + 1]
    const prevMessage = this.messages[index - 1]
    const isNextRoleSame = nextMessage && nextMessage.role === message.role
    const isPrevRoleSame = prevMessage && prevMessage.role === message.role

    return html`<dwc-message
      .position=${isNextRoleSame && !isPrevRoleSame
        ? 'leading'
        : isNextRoleSame && isPrevRoleSame
          ? 'following'
          : !isNextRoleSame && isPrevRoleSame
            ? 'last'
            : 'none'}
      .content=${message.content}
      .role=${message.role}
      ?isTyping=${message.state === 'typing'}
    />`
  }

  render() {
    return html`<div class="container" ${ref(this.connectRef as RefOrCallback<Element>)}>
      ${this.messages.map(this._renderMessage.bind(this))}
      ${!this._isScrolledBottom
        ? html`<button class="scroll-to-bottom" @click=${this._handleScrollToBottom}>
            <dwc-icon name="scrollDown" size="small"></dwc-icon>
          </button>`
        : ''}
    </div>`
  }

  static styles = css`
    :host {
      position: relative;
      display: flex;
      width: 100%;
      height: 100%;
    }

    :host * {
      box-sizing: border-box;
    }

    .container {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      text-align: center;
      width: 100%;
      padding-right: 20px;

      box-sizing: border-box;
      overflow-y: auto;
      overflow-x: hidden;
      scrollbar-gutter: stable;
      scroll-behavior: smooth;
    }

    .container::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }

    .container::-webkit-scrollbar-thumb {
      background-color: var(--color-shade-300);
      border-radius: 6px;
    }

    .container::-webkit-scrollbar-track {
      background-color: var(--color-shade-100);
      border: none;
      border-radius: 6px;
      opacity: 0.5;
    }

    .container::-webkit-scrollbar-button {
      height: 0;
    }

    dwc-message {
      width: 100%;
    }

    .scroll-to-bottom {
      position: absolute;
      bottom: 0;
      right: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 36px;
      height: 36px;
      border-radius: 100%;
      border: none;
      padding: 0;
      cursor: pointer;
      color: var(--color-shade-200);
      background-color: var(--color-shade-100);
      box-shadow: 0px 4px 36px 0px rgba(0, 0, 0, 0.25);
    }
  `
}

declare global {
  interface HTMLElementTagNameMap {
    'dwc-messages': Messages
  }
}
