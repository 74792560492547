import { ReactiveController, ReactiveControllerHost } from 'lit'
import { generateHMAC } from '../../system'

const API_BASE_URL = import.meta.env.VITE_API_BASE_URL || ''

export class APIController implements ReactiveController {
  host: ReactiveControllerHost

  private _apiKey = import.meta.env.VITE_API_KEY || '<Your Chatbot ID>'
  private _roles = new Array<ChatsRoles>('user', 'assistant')
  private _messages = new Array<ChatMessage>()
  private _currentResponseMessage: ChatMessage | null = null
  private _messageResponsePending: ChatMessage = {
    content: '',
    role: this._roles[1],
    state: 'typing',
  }
  private _endpoints = {
    chat: `${API_BASE_URL}/chat`,
    greetings: `${API_BASE_URL}/chat/greetings`,
  }

  public isStarted = false
  public isResponding = false
  public locale: Locales = 'de'

  constructor(host: ReactiveControllerHost) {
    this.host = host
    this.host.addController(this)
  }

  public setLocale(locale: Locales): void {
    this.locale = locale
  }

  public get messages() {
    return this._currentResponseMessage
      ? [...this._messages, this._currentResponseMessage]
      : this._messages
  }

  hostConnected() {
    this.host.requestUpdate()
  }

  hostDisconnected() {}

  clearHistory() {
    this._currentResponseMessage = null
    this._messages = []
    this.host.requestUpdate()
    this.startChat()
  }

  startChat() {
    this._currentResponseMessage = { ...this._messageResponsePending }
    this.isStarted = true
    this.isResponding = true
    this.host.requestUpdate()
    this.getGreeting()
  }

  sendMessage(message: string) {
    if (this.isResponding) return
    this._messages.push({ content: message, role: this._roles[0] })
    this._currentResponseMessage = { ...this._messageResponsePending }
    this.isResponding = true
    this.host.requestUpdate()
    this.requestResponse()
  }

  private async getGreeting() {
    const response = await fetch(`${this._endpoints.greetings}?lang=${this.locale}`, {
      method: 'GET',
      headers: {
        'x-api-key': this._apiKey,
        'Content-Type': 'text/plain',
      },
    })

    if (!response.ok) {
      const errorData = await response.json()
      throw Error(errorData.message)
    }

    const data = await response.text()
    this._messages.push({ content: data, role: this._roles[1] })
    this._currentResponseMessage = null
    this.isResponding = false
    this.host.requestUpdate()
  }

  private async requestResponse() {
    const body = JSON.stringify({
      messages: this._messages,
      timestamp: new Date().getTime(),
    })

    const hash = await generateHMAC(body)
    const response = await fetch(this._endpoints.chat, {
      method: 'POST',
      headers: {
        'x-hmac': hash,
        'x-api-key': this._apiKey,
        'Content-Type': 'application/json',
      },
      body,
    })

    if (!response.ok) {
      const errorData = await response.json()
      throw Error(errorData.message)
    }

    const data = response.body

    if (!data) {
      // error happened
      return
    }

    const reader = data.getReader()
    const decoder = new TextDecoder()
    let isDone = false

    while (!isDone) {
      const { value, done: doneReading } = await reader.read()
      isDone = doneReading

      const chunkValue = decoder.decode(value)
      this.streamResponse(chunkValue, isDone)
    }
  }

  private streamResponse(chunkValue: string, isDone: boolean) {
    if (!this._currentResponseMessage) return

    if (isDone) {
      this._currentResponseMessage.state = 'sent'
      this._messages.push({ content: this._currentResponseMessage.content, role: this._roles[1] })
      this._currentResponseMessage = null
      this.isResponding = false
      this.host.requestUpdate()
      return
    }
    this._currentResponseMessage.state = 'streaming'
    this._currentResponseMessage.content += chunkValue
    this.host.requestUpdate()
  }
}

declare global {
  type ChatsRoles = 'user' | 'assistant'
  interface ChatMessage {
    content: string
    role: ChatsRoles
    state?: 'typing' | 'streaming' | 'sent'
  }
}
