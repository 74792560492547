import IconCancel from '@/assets/whitelabel/icons/cancel.svg?raw'
import IconClear from '@/assets/whitelabel/icons/clear.svg?raw'
import IconClose from '@/assets/whitelabel/icons/close.svg?raw'
import IconProgress from '@/assets/whitelabel/icons/progress.svg?raw'
import IconScrollDown from '@/assets/whitelabel/icons/scroll-down.svg?raw'
import IconSend from '@/assets/whitelabel/icons/send.svg?raw'

import { LitElement, css, html } from 'lit'
import { customElement, property } from 'lit/decorators.js'
import { unsafeSVG } from 'lit/directives/unsafe-svg.js'

const icons: Record<IconName, any> = {
  send: IconSend,
  cancel: IconCancel,
  progress: IconProgress,
  close: IconClose,
  clear: IconClear,
  scrollDown: IconScrollDown,
}

@customElement('dwc-icon')
export class Icon extends LitElement {
  @property({ type: String })
  name: IconName = 'send'

  @property({ type: String })
  size: IconSize = 'medium'

  connectedCallback(): void {
    super.connectedCallback()
    this.classList.add(this.size)
  }

  render() {
    return html`${unsafeSVG(icons[this.name])}`
  }

  static styles = css`
    :host {
      display: inline-block;
      color: currentColor;
    }

    :host(.small) {
      width: 16px;
      height: 16px;
    }

    :host(.medium) {
      width: 20px;
      height: 20px;
    }

    :host(.large) {
      width: 30px;
      height: 30px;
    }

    svg {
      width: 100%;
      height: 100%;
    }
  `
}

declare global {
  interface HTMLElementTagNameMap {
    'dwc-icon': Icon
  }

  type IconName = 'send' | 'cancel' | 'progress' | 'close' | 'clear' | 'scrollDown'
  type IconSize = 'small' | 'medium' | 'large'
}
