import { LitElement, css, html } from 'lit'
import { customElement, property } from 'lit/decorators.js'
import { classMap } from 'lit/directives/class-map.js'
import { Ref, createRef, ref } from 'lit/directives/ref.js'

@customElement('dwc-input')
export class Input extends LitElement {
  @property({ type: String })
  placeholder = ''

  @property({ type: Boolean })
  disabled = false

  @property({ type: Boolean })
  progress = false

  @property({ type: Boolean })
  autofocus: boolean = true

  private _refInput: Ref<HTMLInputElement> = createRef()

  private isDisabled() {
    return this.disabled || this.progress || !this.getValue()
  }

  private getValue() {
    return this._refInput.value?.value
  }

  private clear() {
    this._refInput.value!.value = ''
  }

  private _onSubmit() {
    if (!this.getValue()) return
    this.dispatchEvent(
      new CustomEvent('submit', {
        detail: {
          value: this.getValue(),
        },
        composed: true,
        bubbles: true,
      }),
    )
    this.clear()
  }

  private _onKeyUp(event: KeyboardEvent) {
    if (event.keyCode == 13) {
      this.dispatchEvent(
        new CustomEvent('enter-pressed', {
          detail: {
            value: this.getValue(),
          },
          composed: true,
          bubbles: true,
        }),
      )
      this._onSubmit()
      event.preventDefault()
    } else {
      this.requestUpdate()
    }
  }

  private _onBlur() {
    this.dispatchEvent(
      new CustomEvent('focus-lost', {
        detail: {
          value: this.getValue(),
        },
        composed: true,
        bubbles: true,
      }),
    )
  }

  render() {
    return html`
      <input
        ${ref(this._refInput)}
        type="text"
        placeholder="${this.placeholder}"
        @keyup="${this._onKeyUp}"
        @blur="${this._onBlur}"
      />
      <dwc-button
        class=${classMap({
          'is-loading': this.progress,
        })}
        @click="${this._onSubmit}"
        ?disabled=${this.isDisabled()}
      >
        ${this.progress
          ? html`<dwc-icon class="progress-icon" name="progress" />`
          : html`<dwc-icon name="send" size="large" />`}
      </dwc-button>
    `
  }

  static styles = css`
    :host {
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: space-between;
      position: relative;
      height: 58px;
      --border-radius: 30px;
      min-width: 260px;
    }

    :host * {
      box-sizing: border-box;
    }

    input {
      flex-grow: 1;
      padding: 0 1rem;
      height: 100%;
      box-sizing: border-box;
      border-radius: var(--border-radius);
      border: none;
      color: var(--color-shade-400);
      font-family: var(--font-family);
      outline: none;
      border: 2px solid var(--color-shade-100);
    }

    input::placeholder {
      color: var(--color-shade-200);
    }

    dwc-button {
      position: absolute;
      right: 3px;
      top: 2px;
      bottom: 2px;
      z-index: 1;
      width: 74px;
    }

    .progress-icon {
      animation: spin 1s linear infinite;
      transform-origin: center;
    }

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  `
}

declare global {
  interface HTMLElementTagNameMap {
    'dwc-input': Input
  }
}
