import { LitElement, css, html } from 'lit'
import { customElement, property } from 'lit/decorators.js'

@customElement('dwc-button')
export class Button extends LitElement {
  @property({ type: String })
  value: IconName = 'close'

  @property({ type: Boolean })
  disabled = false

  render() {
    return html`<button ?disabled=${this.disabled}>
      <slot></slot>
    </button>`
  }

  static styles = css`
    :host {
      display: inline-flex;
      min-height: 54px;
      min-width: 54px;
    }

    ::slotted(*) {
      position: relative;
      z-index: 1;
    }

    button {
      font-family: var(--font-family);
      display: inline-flex;
      justify-content: center;
      align-items: center;
      border-radius: 32px;
      padding: 0.2rem 1rem;
      box-sizing: border-box;
      border: none;
      color: var(--color-shade-100);
      background-color: var(--color-shade-300);
      cursor: pointer;
      width: 100%;
      transition: background-color 0.3s ease-in-out;
    }

    button:hover {
      background: var(--color-shade-400);
    }

    button:disabled {
      cursor: not-allowed;
      filter: brightness(85%);
    }
  `
}

declare global {
  interface HTMLElementTagNameMap {
    'dwc-button': Button
  }
}
