import LogoIcon from '@/assets/whitelabel/logo.svg?raw'

import { LitElement, css, html } from 'lit'
import { customElement, property } from 'lit/decorators.js'
import { unsafeSVG } from 'lit/directives/unsafe-svg.js'

@customElement('dwc-logo')
export class Logo extends LitElement {
  @property({ type: String })
  value: IconName = 'send'

  render() {
    return html`${unsafeSVG(LogoIcon)}`
  }

  static styles = css`
    :host {
      display: inline-block;
      color: currentColor;
    }
  `
}

declare global {
  interface HTMLElementTagNameMap {
    'dwc-logo': Logo
  }
}
