import AvatarIcon from '@/assets/whitelabel/avatar-bot.svg?raw'

import { LitElement, css, html } from 'lit'
import { customElement, property } from 'lit/decorators.js'
import { unsafeSVG } from 'lit/directives/unsafe-svg.js'

type Theme = 'light' | 'dark'

@customElement('dwc-avatar')
export class Avatar extends LitElement {
  @property({ type: String })
  theme: Theme = 'dark'

  @property({ type: String })
  size: Sizes = 'large'

  render() {
    return html`${unsafeSVG(AvatarIcon)}`
  }

  static styles = css`
    :host {
      display: inline-block;
      color: var(--color-primary-main);
      transition:
        color 0.5s,
        width 0.5s 0.2s,
        height 0.5s 0.2s;
    }
    svg {
      width: 100%;
      height: 100%;
    }
    :host([size='small']) {
      width: 56px;
      height: 56px;
    }
    :host([size='medium']) {
      width: 96px;
      height: 96px;
    }
    :host([size='large']) {
      width: 108px;
      height: 108px;
    }
    :host([theme='dark']) {
      color: var(--color-primary-dark);
    }
  `
}

declare global {
  interface HTMLElementTagNameMap {
    'dwc-avatar': Avatar
  }
}
