import { LitElement, css, html } from 'lit'
import { customElement, property } from 'lit/decorators.js'
import { classMap } from 'lit/directives/class-map.js'

import { unsafeHTML } from 'lit/directives/unsafe-html.js'
import { marked } from 'marked'

@customElement('dwc-message')
export class Message extends LitElement {
  @property({ type: String })
  content = ''

  @property({ type: String })
  role: ChatsRoles = 'user'

  @property({ type: String })
  position: MessagePosition = 'none'

  @property({ type: Boolean })
  isTyping = false

  connectedCallback(): void {
    super.connectedCallback()
    this.classList.add(this.role)
  }

  render() {
    const classes = {
      content: true,
      'is-leading': this.position === 'leading',
      'is-following': this.position === 'following',
      'is-last': this.position === 'last',
    }
    return html`
      <div class=${classMap(classes)}>
        ${this.isTyping
          ? html`<div class="typing-indicator"><span></span><span></span><span></span></div>`
          : unsafeHTML(marked.parse(this.content) as string)}
      </div>
    `
  }

  static styles = css`
    :host {
      display: flex;
      width: 100%;
      color: var(--color-shade-400);
      animation: move-in 0.3s ease;
    }

    :host * {
      box-sizing: border-box;
    }

    :host(.user) .content {
      background-color: var(--color-shade-300);
      color: var(--color-shade-100);
      margin-left: auto;
      border-bottom-right-radius: 0;
    }

    :host(.assistant) .content {
      margin-right: auto;
      border-top-left-radius: 0;
    }

    .content {
      background-color: var(--color-shade-100);
      border-radius: 19px;
      padding: 18px 24px;
      flex-grow: 0;
      text-align: left;
      max-width: 100%;
    }

    .content > :first-child {
      margin-top: 0;
    }

    .content > :last-child {
      margin-bottom: 0;
    }

    .content a {
      word-wrap: break-word;
    }

    .typing-indicator {
      display: flex;
    }

    .typing-indicator > span {
      height: 10px;
      width: 10px;
      float: left;
      margin: 0 1px;
      background-color: #9e9ea1;
      display: block;
      border-radius: 50%;
      opacity: 0.4;
      animation: 1s blink infinite;
    }

    .typing-indicator > span:nth-of-type(1) {
      animation-delay: 0s;
    }

    .typing-indicator > span:nth-of-type(2) {
      animation-delay: 0.3333s;
    }

    .typing-indicator > span:nth-of-type(2) {
      animation-delay: 0.6666s;
    }

    @keyframes move-in {
      from {
        opacity: 0;
        transform: translateY(10%);
      }
      to {
        opacity: 1;
        transform: translateY(0);
      }
    }

    @keyframes blink {
      50% {
        opacity: 1;
      }
    }
  `
}

declare global {
  interface HTMLElementTagNameMap {
    'dwc-message': Message
  }

  type MessagePosition = 'leading' | 'following' | 'last' | 'none'
}
