import { LitElement, css, html } from 'lit'
import { customElement, property, state } from 'lit/decorators.js'

import { APIController } from '../../controller/api-controller'

import allTranslations from '../../assets/translations.json'
import { themeDataToCSS } from '../../system/theme'
import themeData from './[theme].json'
const themeCSS = themeDataToCSS(themeData as any)

@customElement('dwc-chat')
export class Chat extends LitElement {
  @property({ type: String })
  public locale: Locales = 'de'

  @state()
  private isOpen = false

  @state()
  private isStarted = false

  @state()
  private translations: Record<string, string> = {}

  private api = new APIController(this)

  connectedCallback() {
    super.connectedCallback()
    this.translations = allTranslations[this.locale]
    this.api.setLocale(this.locale)
  }

  willUpdate(changedProperties: Map<PropertyKey, unknown>) {
    if (!changedProperties.has('locale')) return
    this.translations = allTranslations[this.locale]
    this.api.setLocale(this.locale)
  }

  private _confirmDialog(bool: boolean) {
    this.isStarted = true
    if (bool) this._toggleChat()
  }

  private _toggleChat() {
    this.isStarted = true
    this.isOpen = !this.isOpen
    if (this.isOpen && !this.api.isStarted) this.api.startChat()
    this.requestUpdate()
  }

  private _renderWindow = () => {
    return html`
      <dwc-window
        class="chat-window"
        .isOpen=${this.isOpen}
        theme=${this.isStarted ? 'avatar' : 'logo'}
        @close=${this._toggleChat}
        @toggle=${this._toggleChat}
      >
        <div slot="meta">
          <dwc-icon-button icon="clear" @click=${() => this.api.clearHistory()}></dwc-icon-button>
        </div>
        <div slot="content">
          <dwc-messages .messages=${this.api.messages}></dwc-messages>
        </div>
        <div slot="footer">
          <dwc-input
            class="chat-input"
            placeholder=${this.translations['input.placeholder']}
            ?disabled=${!this.api.isStarted || this.api.isResponding}
            .progress=${this.api.isResponding}
            @submit=${(e: CustomEvent) => this.api.sendMessage(e.detail.value)}
          ></dwc-input>
        </div>
      </dwc-window>
    `
  }

  private _renderDialog = () => {
    return html`
      <dwc-dialog
        class="chat-dialog"
        .isOpen=${this.isOpen}
        @cancel=${() => this._confirmDialog(false)}
        @accept=${() => this._confirmDialog(true)}
      >
        <span> ${this.translations['dialog.message']}</span>
        <span slot="accept"> ${this.translations['dialog.confirm']} </span>
        <span slot="cancel"> ${this.translations['dialog.cancel']} </span>
      </dwc-dialog>
    `
  }

  render() {
    return html`${this._renderWindow()} ${!this.isStarted ? this._renderDialog() : ''}`
  }

  static styles = [
    themeCSS,
    css`
      :host {
        --gutter: 1rem;
        position: fixed;
        right: 0;
        bottom: 0;
        display: block;
        font-family: var(--font-family);
        width: 100%;
        height: 96px;
      }

      :host * {
        box-sizing: border-box;
        font-family: var(--font-family);
      }

      .chat-window {
        position: absolute;
        right: var(--gutter);
        bottom: var(--gutter);
        transition: transform 0.25s ease 0.5s;
      }

      .chat-window.is-open {
        transform: translate(calc(var(--gutter)), calc(var(--gutter)));
        transition: transform 0.25s ease;
      }

      .chat-input {
        width: 100%;
      }

      .chat-dialog {
        position: absolute;
        bottom: calc(var(--gutter) * 8);
        right: calc(var(--gutter) * 4);
      }

      @media (min-width: 458px) {
        :host {
          width: 96px;
          height: 96px;
        }

        .chat-window {
          position: absolute;
          width: auto;
          left: auto;
        }

        .chat-window.is-open {
          transform: none;
        }

        .chat-dialog {
          bottom: var(--gutter);
          right: calc(var(--gutter) * 8);
        }
      }
    `,
  ]
}

declare global {
  interface HTMLElementTagNameMap {
    'dwc-chat': Chat
  }
}
