import { LitElement, css, html } from 'lit'
import { customElement, property } from 'lit/decorators.js'
import { classMap } from 'lit/directives/class-map.js'

@customElement('dwc-dialog')
export class Dialog extends LitElement {
  @property({ type: Boolean })
  isOpen = true

  render() {
    return html`
      <div
        class=${classMap({
          container: true,
          'is-open': this.isOpen,
        })}
      >
        <dwc-icon-button class="close" icon="close" @click="${this._cancel}"></dwc-icon-button>
        <dwc-avatar class="avatar" theme="dark" size="large"></dwc-avatar>

        <div class="content">
          <p class="text"><slot></slot></p>
          <div class="actions">
            <button @click="${this._accept}">
              <slot name="accept"></slot>
            </button>
            <button @click="${this._cancel}">
              <slot name="cancel"></slot>
            </button>
          </div>
        </div>
      </div>
    `
  }

  private _accept() {
    this.isOpen = false
    this.dispatchEvent(
      new CustomEvent('accept', {
        composed: true,
        bubbles: false,
      }),
    )
  }

  private _cancel() {
    this.isOpen = false
    this.dispatchEvent(
      new CustomEvent('cancel', {
        composed: true,
        bubbles: false,
      }),
    )
  }

  static styles = css`
    :host {
      position: relative;
      display: block;
      font-family: var(--font-family);
      font-size: 12px;
      font-weight: 700;
    }

    :host * {
      box-sizing: border-box;
      font-family: var(--font-family);
      font-weight: 700;
    }

    .container {
      position: relative;
      display: flex;
      flex-direction: column;

      padding: 24px;

      overflow: hidden;
      color: var(--color-shade-300);
      background: var(--color-primary-main);
      border-radius: 32px;
      border-bottom-right-radius: 0;
      box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.1);

      transition:
        width 0.4s 0.2s,
        height 0.5s 0.4s,
        border-radius 0.3s;
    }

    .close {
      position: absolute;
      top: 1rem;
      right: 1rem;
      display: flex;
      gap: 1rem;
      opacity: 0;
      animation: fade-in 0.25s 0.4s forwards;
    }

    .content {
      position: relative;
      margin-left: auto;
      max-width: 240px;
    }

    .text {
      margin-top: 20px;
      margin-bottom: 20px;
    }

    .actions {
      display: flex;
      gap: 12px;
    }

    button {
      background: none;
      border: none;
      cursor: pointer;
      color: inherit;
      border-radius: 19px;
      height: 28px;
      min-width: 96px;
      border: 1px solid var(--color-shade-100);
    }

    button:nth-child(1) {
      background: var(--color-shade-100);
    }

    @media (min-width: 458px) {
      :host {
        font-size: 18px;
      }

      .container {
        flex-direction: row;
        align-items: center;
        padding: 40px 40px 40px 51px;
      }

      .content {
        position: relative;
        margin-left: auto;
        max-width: 240px;
        margin-left: 51px;
      }
      button {
        border-radius: 19px;
        height: 38px;
        min-width: 114px;
      }
      .text {
        margin-top: 0;
        margin-bottom: 10px;
      }
    }
  `
}

declare global {
  interface HTMLElementTagNameMap {
    'dwc-dialog': Dialog
  }
}
