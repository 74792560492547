import { LitElement, PropertyValueMap, css, html } from 'lit'
import { customElement, property } from 'lit/decorators.js'
import { Ref, createRef, ref } from 'lit/directives/ref.js'

type Theme = 'logo' | 'avatar'

@customElement('dwc-window')
export class Window extends LitElement {
  private _refContainer: Ref<HTMLDivElement> = createRef()
  private _refContent: Ref<HTMLDivElement> = createRef()
  private _refHeader: Ref<HTMLDivElement> = createRef()
  private _refFooter: Ref<HTMLDivElement> = createRef()

  @property({ type: Boolean })
  public isOpen: boolean | undefined = undefined

  @property({ type: String })
  theme: Theme = 'logo'

  connectedCallback() {
    super.connectedCallback()
    window.addEventListener('resize', this._handleResize)
    this._resize()
    if (this.isOpen === false) {
      this.classList.remove('is-open')
    } else {
      this.classList.add('is-open')
    }
  }

  disconnectedCallback() {
    super.disconnectedCallback()
    window.removeEventListener('resize', this._handleResize)
  }

  willUpdate(changedProperties: Map<PropertyKey, unknown>) {
    if (!changedProperties.has('isOpen')) return

    if (this.isOpen === false) {
      this.classList.remove('is-open')
    } else {
      this.classList.add('is-open')
    }
  }

  protected firstUpdated(
    _changedProperties: PropertyValueMap<any> | Map<PropertyKey, unknown>,
  ): void {
    this._resize()
  }

  private _close() {
    this.isOpen = false
    this.dispatchEvent(
      new CustomEvent('close', {
        composed: true,
        bubbles: false,
      }),
    )
  }
  private _toggle() {
    this.dispatchEvent(
      new CustomEvent('toggle', {
        composed: true,
        bubbles: false,
      }),
    )
  }

  private _resize() {
    if (!this._refContainer || !this._refContent || !this._refHeader || !this._refFooter) return
    const isMobile = window.innerWidth < 458
    this._refContent.value!.style.height = ''
    this._refContainer.value!.style.width = ''
    this._refContainer.value!.style.height = ''

    requestAnimationFrame(() => {
      const headerHeight = this._refHeader.value!.getBoundingClientRect().height
      const footerHeight = this._refFooter.value!.getBoundingClientRect().height
      const offset = 100
      const containerHeight = Math.min(window.innerHeight - offset, 820)
      const contentHeight = containerHeight - headerHeight - footerHeight
      this._refContent.value!.style.height = `${contentHeight}px`
      this._refContainer.value!.style.height = `${containerHeight}px`
      this._refContainer.value!.style.width = `${Math.min(window.innerWidth, 458)}px`
      if (isMobile) {
        this._refContainer.value!.style.borderRadius = `16px`
        this._refContainer.value!.style.borderBottomLeftRadius = `0`
        this._refContainer.value!.style.borderBottomRightRadius = `0`
      } else {
        this._refContainer.value!.style.borderRadius = `16px`
      }
    })
  }

  private _handleResize = () => {
    this._resize()
  }

  render() {
    return html`
      <div class="container" ${ref(this._refContainer)}>
        <div class="header" ${ref(this._refHeader)}>
          <button class="toggle" @click=${this._toggle}>
            <dwc-avatar
              class="avatar"
              size=${this.isOpen ? 'small' : 'medium'}
              theme=${this.isOpen ? 'dark' : 'light'}
            ></dwc-avatar>
            ${this.theme === 'logo' ? html` <div class="logo"><dwc-logo></dwc-logo></div>` : null}
          </button>
          <div class="tools">
            <slot name="meta"></slot>
            <dwc-icon-button icon="close" @click="${this._close}"></dwc-icon-button>
          </div>
        </div>
        <div class="content" ${ref(this._refContent)}>
          <slot name="content"></slot>
        </div>
        <div class="footer" ${ref(this._refFooter)}>
          <slot name="footer"></slot>
        </div>
      </div>
    `
  }

  static styles = css`
    :host {
      position: relative;
      display: block;
    }

    :host * {
      box-sizing: border-box;
    }

    .container {
      position: relative;
      max-width: 458px;
      color: var(--color-shade-300);
      width: 96px;
      height: 96px;
      overflow: hidden;

      transition:
        width 0.4s 0.2s,
        height 0.5s 0.4s,
        border-radius 0.3s;

      border-radius: 100%;
      background: var(--color-primary-main);
      border-top-left-radius: inherit;
      border-top-right-radius: inherit;
      box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.1);
    }

    :host(:not(.is-open)) .container {
      width: 96px !important;
      height: 96px !important;
      border-radius: 100% !important;
      transition:
        width 0.4s 0.3s,
        height 0.5s,
        border-radius 0.3s 0.6s;
    }

    .header {
      position: relative;
      display: flex;

      transition:
        padding 0.25s 0.3s,
        transform 0.25s;
    }

    :host(.is-open) .header {
      animation: move-in 0.25s 0.4s forwards;
      padding: 32px 32px 16px;

      transition:
        padding 0.25s 0.2s,
        transform 0.25s;
    }

    .toggle {
      position: relative;
      overflow: hidden;
      border: none;
      border-radius: 100%;
      cursor: pointer;
      padding: 0;
      max-width: 96px;
      max-height: 96px;
    }

    :host(.is-open) .toggle {
      pointer-events: none;
    }

    .logo {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--color-shade-100);
      z-index: 1;
    }

    .avatar {
      opacity: 0;
    }

    :host([theme='avatar']) .avatar {
      animation: fade-in 0.3s 0.2s forwards;
    }

    .header .tools {
      position: absolute;
      top: 1rem;
      right: 1rem;
      display: flex;
      gap: 1rem;
      opacity: 0;
      pointer-events: none;
    }

    :host(.is-open) .header .tools {
      animation: fade-in 0.25s 0.4s forwards;
      pointer-events: auto;
    }

    .footer {
      position: relative;
      display: flex;
      justify-content: center;
      padding: 16px 32px 32px;
      width: 100%;
    }

    .footer ::slotted(*) {
      width: 100%;
    }

    button {
      background: none;
      border: none;
      cursor: pointer;
      color: inherit;
    }

    .content {
      position: relative;
      height: 300px;
      width: 100%;
      padding: 0 16px;
    }

    .content ::slotted(*) {
      height: 100%;
      width: 100%;
    }

    .content::-webkit-scrollbar {
      width: 8px;
    }

    @media (min-width: 458px) {
      :host {
        margin-right: 0;
        margin-bottom: 0;
        animation: none;
      }

      .container {
        width: 96px;
        border-radius: 100%;
        transition:
          width 0.4s 0.1s,
          height 0.5s 0.3s,
          border-radius 0.25s;
      }

      :host(:not(.is-open)) .container {
        width: 96px !important;
        height: 96px !important;
        border-radius: 100% !important;
        transition:
          width 0.4s 0.3s,
          height 0.5s,
          border-radius 0.25s 0.5s;
      }

      :host(.is-open) .header {
        animation: move-in 0.25s 0.2s forwards;
      }

      :host(.is-open) .header .tools {
        animation: fade-in 0.25s 0.25s forwards;
      }
    }

    @keyframes fade-in {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }

    @keyframes move-in {
      from {
        transform: translateY(-12%);
      }
      to {
        transform: translateY(0);
      }
    }
  `
}

declare global {
  interface HTMLElementTagNameMap {
    'dwc-window': Window
  }
}
